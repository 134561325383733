import React from 'react'

import AccordionFaq, { AccordionFaqProps } from './AccordionFaq'
import AccordionFaqMinimal from './variants/AccordionFaqMinimal'
import WillsAccordionFaq from './variants/WillsAccordionFaq'

const AccordionFaqWrapper = ({ moduleData }: AccordionFaqProps) => {
  if (moduleData.variant === 'wills') {
    return <WillsAccordionFaq moduleData={moduleData} />
  }
  if (moduleData.variant === 'minimal') {
    return <AccordionFaqMinimal moduleData={moduleData} />
  }

  return <AccordionFaq moduleData={moduleData} />
}

export default AccordionFaqWrapper

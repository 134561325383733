import React, { useState } from 'react'

import styles from './AccordionFaqMinimal.module.scss'

interface AccordionFaqWrapperProps {
  faqs: {
    title: string
    content: string
  }[]
  heading: string
}

const AccordionFaqMinimal = ({
  moduleData,
}: {
  moduleData: AccordionFaqWrapperProps
}) => {
  const { heading } = moduleData

  // dict to keep track of open items and sets first item to open by default
  const [itemsOpen, setItemsOpen] = useState<{ [key: number]: boolean }>({
    [0]: true,
  })
  const handleAccordionClick = (index: number) => {
    setItemsOpen({ ...itemsOpen, [index]: !itemsOpen[index] })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h3 className="body-l-med pb-4 md:pb-8 md:!text-3xl">{heading}</h3>
        <div className="flex w-full flex-col gap-y-2 md:gap-y-4">
          {moduleData.faqs.map(({ title, content }, index) => (
            <div
              key={title}
              className="cursor-pointer rounded-lg bg-gray-6 px-4 py-2 md:px-8 md:py-4"
              onClick={() => handleAccordionClick(index)}
            >
              <Accordion
                isOpen={itemsOpen[index]}
                accordionTitle={title}
                accordionContent={content}
                iconDirection="right"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

interface AccordionProps {
  accordionTitle: string
  accordionContent: string
  iconDirection: 'left' | 'right'
  isOpen: boolean
}

const Accordion = ({
  accordionTitle,
  accordionContent,
  isOpen,
}: AccordionProps) => {
  return (
    <div className={styles.accordion}>
      <div className="flex w-full items-center justify-between">
        <span className={styles.accordionTitle}>{accordionTitle}</span>
        <div className="h-6 w-6">{isOpen ? <MinusIcon /> : <PlusIcon />}</div>
      </div>
      {isOpen && (
        <span className={styles.accordionContent}>{accordionContent}</span>
      )}
    </div>
  )
}

const PlusIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#525252" />
  </svg>
)

const MinusIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 13H5V11H19V13Z" fill="#525252" />
  </svg>
)

export default AccordionFaqMinimal

import React from 'react'

export const PlusIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 11H4V13H20V11Z" fill="#727272" />
      <path d="M13 20L13 4L11 4L11 20L13 20Z" fill="#727272" />
    </svg>
  )
}
